<template>
    <div v-if="editMode" class="vstack">
        <OTextArea v-model="filterString" class="form-control from-control-sm" autocomplete="false" spellcheck="false" autoGrow noResize
            :class="{'is-invalid': hasError}" rows="1" @keydown.enter.prevent="apply"/>
        <div class="hstack flex-reverse">
            <button :disabled="!hasChanges" class="btn btn-sm btn-link" @click="apply">
                {{ $t('Apply') }}
            </button>
            <button v-if="hasChanges" class="btn btn-sm btn-link" @click="cancel">
                {{ $t('Cancel') }}
            </button>
            <button class="btn btn-sm btn-link ms-auto" @click="editMode = false">
                {{ $t('Close') }}
            </button>
        </div>
    </div>
    <div v-else class="hstack">
        <button class="btn btn-sm btn-link" @click="editMode = true">
            {{ $t('Edit filter string') }}
        </button>
    </div>
</template>

<script setup lang="ts">
import type FilterObject from 'o365.modules.FilterObject.ts';
import OTextArea from 'o365.vue.components.TextArea.vue';
import { watch, ref, computed } from 'vue';


const props = defineProps<{
    filterObject: FilterObject;
}>();

const filterString = ref(props.filterObject.appliedFilterString);
const editMode = ref(false);
const hasError = ref(false);

const hasChanges = computed(() => filterString.value !== props.filterObject.appliedFilterString);

watch(() => props.filterObject.appliedFilterString, (newFilter) => {
    filterString.value = newFilter;
});
watch(() => filterString.value, () => {
    if (hasError.value) { hasError.value = false; }
});

async function apply() {
    props.filterObject.applyInitFilter(filterString.value,true);
    /*props.filterObject.resetFilterItems();
    props.filterObject.dataObject.recordSource.filterString = filterString.value;

    props.filterObject._filterString = '';
    props.filterObject._prettyString = filterString.value;
    try {
        await props.filterObject.dataObject.load(undefined, true);
    } catch (ex) {
        hasError.value = true;
    }*/
}

function cancel() {
    filterString.value = props.filterObject.appliedFilterString;
}
</script>